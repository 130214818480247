<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 75%;display: flex;">
					<el-input class="hunt" v-model="designation" placeholder="请输入会员姓名" size="large" @input="orangutan" clearable />
					<el-input class="hunt" v-model="serial" placeholder="请输入会员编码" size="large" @input="legs" clearable />
					<!-- 会员组织名称搜索 -->
					<el-select class="hunt" @focus="closeIt" v-model="ability" placeholder="请选择所属工会" size="large"
						@click="multiple" clearable @change="numerical" ref="selectIt" />
					<!-- 派发时间 -->
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始时间"
						end-placeholder="结束时间" size="large" @change="immediUpdate" />
					<!-- 搜索 -->
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
			</div>
			<div class="summation">
				<div class="hint">统计 : {{zong}}篇</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" :row-key="obtain => { return obtain.id}" @cell-mouse-enter="obtain" border
				:empty-text="texts" :header-cell-style="{ background: '#F5F7FA' }" @row-click="unit">
				<!-- 会员姓名  -->
				<el-table-column fixed label="会员姓名" prop="name" align="center" :show-overflow-tooltip='true'
					width="120">
				</el-table-column>
				<!-- 会员编码  -->
				<el-table-column label="会员编码 " prop="username" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 所在组织 -->
				<el-table-column label="所属工会" prop="unionName" align="center" :formatter="quantum" width="200"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 商品名称 -->
				<el-table-column label="商品名称 " prop="goodsName" align="center" :show-overflow-tooltip='true' width="450">
				</el-table-column>
				<!-- 订单号 -->
				<el-table-column label="订单号 " prop="orderNumber" align="center" :show-overflow-tooltip='true' width="200">
				</el-table-column>
				<!-- 支付方式 -->
				<el-table-column label="支付方式 " prop="payType" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 现金 -->
				<el-table-column label="现金 " prop="money" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 积分 -->
				<el-table-column label="积分 " prop="integral" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 返还积分  -->
				<el-table-column label="返还积分  " prop="refundIntegral" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 订单状态 -->
				<el-table-column label="订单是否有效 " prop="orderState" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 操作管理员  -->
				<el-table-column label="操作人  " prop="adminName" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 操作类型  -->
				<el-table-column label="操作类型  " prop="type" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 操作时间 -->
				<el-table-column label="操作时间" prop="createTime" align="center" :formatter="reach" width="180"
						:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 备注 -->
				<el-table-column label="备注" prop="reason" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>

			</el-table>
			<!-- 表格分页 -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
	</div>
</template>
<script>
import { Search } from "@element-plus/icons-vue";
import { useRouter,
		useRoute } from "vue-router";
import {ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount,
		onActivated} from 'vue';
import { FYdefeated,PointsList,PFintegral,
		FYintegral,
		FPtotal,ZClist} from '../../utils/api'
import qs from 'qs'
import moments from 'moment'
// import { ElMessage } from "element-plus";
export default {
  name:"JFreturn",
  setup() {
  	const route = useRoute();
  	// 搜索按钮加载
  	let formative = ref(false)
  	// 成功弹窗
  	let anew = ref(false)
  
  	let quoth = ref('')
  
  	// 下拉隐藏
  	let selectIt = ref()
  	let closeIt = () => {
  		selectIt.value.blur();
  	}
  
  	// 加载中
  	const acting = ref(false)
  	// 按钮禁用
  	const jinyong = ref(false)
  	// 工会清空
  	const numerical = (e) => {
  		if (e == '') {
  			 Hcode.value=''
  		}
  	}
  
  	// 导入
  	// const error=(e)=>{
  	//   console.log(e)
  	// }
  	const pleased = ref()
  
  
  
  
  	// 数据总共条数
  	let zong = ref(null)
  	// let reach = (row, colum, cellValue) => {
  	// 	if (typeof cellValue == 'number') {
  	// 		return moments(cellValue * 1000).format().substring(0, 10)
  	// 	}
  	// }
  	let reach = (row, colum, cellValue) => {
  		if (typeof cellValue == 'number') {
  			return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
  		}
  	}
  	// 列表数据循环
  	const filterTableData = ref([])
  	// 表格数据接口
  	let list = () => {
  		let data = {
  			limit: branches.value,
  			page: currentPage.value
  		}
  		PointsList(qs.stringify(data)).then((res) => {
  			console.log(res.data.data.refundIntegralPage.totalCount)
  			if (res.data.code == 0) {
  				zong.value = res.data.data.refundIntegralPage.totalCount
  				filterTableData.value = res.data.data.refundIntegralPage.list
  				console.log(filterTableData.value)
  				if (filterTableData.value.length == 0) {
  					texts.value = '暂无数据'
  				}
  			}
  		})
  	}
  	// 分页获取积分的派发日志
  	// 动态加载数据
  	let branches = ref(6)
  	// 分页
  	let currentPage = ref(1)
  	let pagesize = ref(6)
  	let handleSizeChange = (size) => {
  		console.log(size)
  		pagesize.value = size
  		branches.value = size
  		if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b.value !== '') {
  			search()
  		} else {
  			list()
  		}
  	}
  	let handleCurrentChange = (size) => {
  		console.log(size)
  		currentPage.value = size
  		texts.value = '加载中'
  		if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b.value !== '') {
  			search()
  		} else {
  			list()
  		}
  		// console.log(currentPage.value)
  		// console.log(branches.value )
  	}
  
  
  	// 加载中
  	let texts = ref('加载中')
  	let translate = ref(2)
  	onActivated(() => {
  		translate.value = route.params.userId
  		if (translate.value == 1) {
  			a.value = ''
  			b.value = ''
  			unitAbbreviation.value = ''
  			username.value = ''
  			name.value = ''
  			texts.value = '加载中'
  			branches.value = 6
  			currentPage.value = 1
  			list()
  			// 所有组织列表接口
  			lang()
  		}
  	})
  
  	let search1 = () => {
  		formative.value = true
  		search()
  	}
  
  
  	// 搜索框点击
  	let search = () => {
  		// 时间截止
  		// 工会code
  		// console.log(Hcode.value)
  		// 会员编码框值
  		// console.log(serial.value)
  		// 姓名
  		// console.log(designation.value)
  		console.log(a.value)
  		console.log(b.value)
  		// 搜索接口
  		let data = {
  			limit: branches.value,
  			page: currentPage.value,
  			// 开始时间 结束时间
  			startTime: a.value,
  			endTime: b.value,
  			// 工会标识
  			 unionCode:Hcode.value,
  			username:serial.value,
  			name:designation.value, 
  		}
  		PointsList(qs.stringify(data)).then((res) => {
  			console.log(res)
  			if (res.data.code == 0) {
  				formative.value = false
  				zong.value = res.data.data.refundIntegralPage.totalCount
  				filterTableData.value = res.data.data.refundIntegralPage.list
  				// console.log(filterTableData.value)
  				if (filterTableData.value.length == 0) {
  					texts.value = '暂无数据'
  				}
  			} else if (res.data.code !== 0) {
  				ElMessage({
  					message: res.data.msg,
  					type: "error",
  					center: true,
  				});
  			}
  		})
  	}
  
  	let a = ref('')
  	let b = ref('')
  
  	// 时间清空监听
  	const immediUpdate = (e) => {
  		if (e !== null) {
  			a.value = new Date(e[0]).getTime() / 1000
  			b.value = new Date(e[1]).getTime() / 1000
  		} else if (e == null) {
  			a.value = ''
  			b.value = ''
  		}
  		// console.log(e)
  	}
  
  	// 搜索组织列表接口
  	// 获取code 
  	let Hcode = ref('')
  	// 搜索组织选择值
  	let ability = ref('')
  	// 标识
  	let unitAbbreviation = ref('')
  	// 弹窗树形选择
  let dendrogram = (node) => {
	  console.log(node.unionCode)
  ability.value=node.unionName
  Hcode.value=node.unionCode
	// unitAbbreviation.value = node.unitAbbreviation
	// console.log(Hcode.value)
  }
  	let treeData = ref([])
  	const yes1 = () => {
  		form.coding = options.value.unionName
  		visible1.value = false;
  	};
  
  
  	onMounted(() => {
  		// 获取当前页path
  		lang()
  		list()
  	})
  	const lang = () => {
  		ZClist().then((res) => {
			console.log(res)
  			if (res.data.code == 0) {
  				texture.value = res.data.data.manageUnionList
  			}
  		})
  		// console.log('44657687')
  	}
  	// 弹窗组织列表树形
  	let texture = ref([])
  	const defaultProps = {
  		children: 'unionDtoList',
  		label: 'unionName',
  	}
  	// 选择组织按钮
  	let multiple = () => {
  		visible.value = true
  	}
  	// 搜索组织弹窗
  	let visible = ref(false)
  
  
  
  	// 会员编码框
  	let serial = ref('')
  	// 搜索框变化值
  	let orangutan = () => {
  		// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
  		//   list()
  		//   console.log('空了')
  		// }
  		// console.log('变化了')
  	}
  	// 姓名输入框值
  	let designation = ref('')
  
  	// 时间选择
  	let value1 = ref('')
  	const whirl = ref(false)
  	onMounted(() => {
  		//选择工会下拉框数据
  		lang()
  		//列表页数据
  		list()
  		// //头部积分数据
  		// gration()
  	})
  	// 待办内容点击事件
  	const router = useRouter()
  	// 待办内容
  	const tableData = [];
  	// 头像加载失败
  	const errorHandler = () => true;
  	// 头部添加跳转
  	let append=()=>{
  		console.log(111)
  	  router.push('/returnPoints')
  	}
  	return {
  		formative,
  		search1,
  		quoth,
  		anew,
  		selectIt,
  		closeIt,
  		// error,
  		jinyong,
  		numerical,
  
  		immediUpdate,
  		reach,
  		filterTableData,
  		// 分页
  		zong,
  		branches,
  		handleCurrentChange,
  		handleSizeChange,
  		currentPage,
  		pagesize,
  		// 组织弹窗
  		ability,
  		texture,
  		visible,
  		// yes,
  		dendrogram,
  		defaultProps,
  		multiple,
  		yes1,
  		treeData,
  		// 会员编码框
  		serial,
  		// 搜索框
  		search,
  		// 姓名框变化值
  		orangutan,
  		// 会员姓名
  		designation,
  		// 时间选择
  		whirl,
  		value1,
  		errorHandler,
  		tableData,
  		texts,
  		append,
  	};
  },
  components: {
  	Search,
  },
};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	.summation {
		display: flex;
		justify-content: space-between;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	.el-select--large {
		width: 20%;
		// margin-left: 10px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 20%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
<style>
	/* ::-webkit-scrollbar{display:none} */
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>
